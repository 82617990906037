.App {
  text-align: center;
}

body {
 width: 100vw;
 margin: auto;
 overflow-x: hidden;
 background: rgb(0,0,0);
 background: -moz-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
 background: -webkit-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
 background: linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#141301",GradientType=1);
 font-family: Roboto, sans-serif;
}
@font-face {
  font-family: 'Chango';
  src: local('Chango-Regular'), url('../public/titlefont/Chango-Regular.ttf') format('truetype');
  font-weight: normal; /* Estilo da fonte normal */
  font-style: normal; /* Estilo da fonte normal */
}

h1, h2 {
  font-family: 'Chango', sans-serif;
  line-height: 1.2s;
  margin-bottom: -10px; 
  font-size: 45px;
}

h3, h4, h5, h6 {
  line-height: 1.2s; /* Defina um valor menor para reduzir o espaçamento vertical entre as letras */
}
.fa {
  background-color: transparent;
  color: yellow;
  margin-left: 18px;
  border-radius: 2px;
  transition: background-color 0.6s, border-radius 0.1s, color 0.3s, border 0.4s;
}
.fa:hover{
  border-radius: 3px;
  color: rgb(34, 34, 30);
  background-color: yellow;
  border: 4px solid yellow;
}

.yellowbutton {
  background-color: yellow;
  color: black;
  border: none;
  padding: 5px 18px;
  cursor: pointer;
  transition: background-color 0.5s, border-color 0.3s, color 0.3s;
  z-index: 2;
}

.yellowbutton:hover {
  background-color: transparent;
  border: 1px solid white;
  color: white;
}

/* nave bar ---------------------------------------------------------------------------------------------------------------------- */
nav {
  margin: 0;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  z-index: 5;
}
@media screen and (max-width: 576px) {
  nav {
    height: 35px;
    background-color: black;
  }
  nav a{
    font-size: 10px;
    cursor: crosshair;
  }
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: font-size 0.5s;
}

a:hover {
  font-size: 18px;
}


/* FOOTER --------------------------------------------------------------------------------------------------------------*/

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  width: 100vw;
  color:rgba(8, 108, 108, 0.426);
  margin: 0;
  border: 0;
  background: rgb(4,25,21);
  background: -moz-linear-gradient(91deg, rgba(4,25,21,1) 0%, rgba(2,10,33,1) 6%, rgba(18,3,31,0.9635503859747023) 19%, rgba(1,10,18,1) 88%, rgba(4,27,28,0.9607492655265231) 100%);
  background: -webkit-linear-gradient(91deg, rgba(4,25,21,1) 0%, rgba(2,10,33,1) 6%, rgba(18,3,31,0.9635503859747023) 19%, rgba(1,10,18,1) 88%, rgba(4,27,28,0.9607492655265231) 100%);
  background: linear-gradient(91deg, rgba(4,25,21,1) 0%, rgba(2,10,33,1) 6%, rgba(18,3,31,0.9635503859747023) 19%, rgba(1,10,18,1) 88%, rgba(4,27,28,0.9607492655265231) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#041915",endColorstr="#041b1c",GradientType=1);
  padding: 5px;
  overflow-x: hidden;
}
.footerText {
  width: 350px;
  margin-top: 40px;
}


/*---------------------------------------- ANIMATION ------------*/

/* Adicione uma classe para a animação */
.rotate-animation {
  animation: rotate 4s linear infinite; /* Ajuste a duração e o timing conforme necessário */
}

/* Defina a animação de rotação do disco*/
@keyframes rotate {
  from {
    transform: rotate(4deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*dice animation */
.startPlay {
  color: rgb(220, 240, 71);
  z-index: 1; 
}



.dice{
  width: 50%;
  height: 70%;
  top: 0;
  margin-top: 25px;
  padding-top: 12%;
  padding-right: 35%;
  position: absolute;
  right: 0;
  overflow: hidden;
  color: rgb(246, 248, 249);
}

@media screen and (max-width: 576px) {
  .dice{
    margin-top: 40px;;
    padding-top: 72%;
    padding-right: 25%;
    padding-right: 25%;
    height: 50%;
    width: 80%;
    }

}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .dice{
  padding-top: 13%;
  padding-right: 22%;
  }

  .dice-action div {
    width: 140px;
    height: 120px;
  }

    .face-1 {
      transform: translateZ(50px);
      
    }
    .face-2 {
      transform: rotateY(90deg) translateZ(50px);
     
    }
    .face-3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(50px);
      
    }
    .face-4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(50px);
      
    }
    .face-5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(50px);
     
    }
    .face-6 {
      transform: rotateX(-90deg) translateZ(50px);
    }
}


.dice-action div {
        position: absolute;
        width: 160px;
        height: 160px;
        border: 1px solid #f07808;
        background: transparent;
        text-align: center;
        font-size: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px rgb(229, 230, 173);
        top: 75px;
}

.dice-action{
    animation-name: diceaction;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 75px 75px 0;
    margin-left: calc(50% - 50px);
}

.face-1 {
  transform: translateZ(80px);
  
}
.face-2 {
  transform: rotateY(90deg) translateZ(80px);
 
}
.face-3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(80px);
  
}
.face-4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(80px);
  
}
.face-5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(80px);
 
}
.face-6 {
  transform: rotateX(-90deg) translateZ(80px);
}

@keyframes diceaction {
  from,
to {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
12% {
  transform: rotateY(-90deg) rotateZ(90deg);
}
24% {
  transform: rotateY(-180deg) rotateZ(145deg);
}
38% {
  transform: rotateY(-270deg) rotateX(180deg);
}
50% {
  transform: rotateY(-180deg) rotateZ(360deg);
}
62% {
  transform: rotateY(-135deg) rotateX(180deg);
}
74% {
  transform: rotateY(-90deg) rotateX(90deg);
}
86% {
  transform: rotateX(360deg);
}
}


.falling-animation {
  animation-name: fall;
  animation-duration: 2s;
  animation-timing-function: ease-in-out; 
  animation-fill-mode: forwards; 
}

@keyframes fall {
  0% {
    top: 0;
  }
  100% {
    top: 70%; 
  }
}


/*---------------------------------------------*/

.circle {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid #ffffff5a;
}
.clip-circle {
  position: absolute;
  padding: 0;
  top: -16px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  clip-path: circle(99px at center);
}
.lighting:after {
    content: '';
    position: absolute;
    top: 43px;
    left: 61px;
}

.metal {
  position: absolute;
  padding: 20px;
  opacity: 1;
  width: 200px;
  height: 200px;
  animation-name: zigzag;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.metal .lighting {
  box-shadow: -20px -30px 100px 0 rgba(216, 234, 255, 0.036) inset, -75px -100px 107px 0 rgba(0, 0, 0, 0.3) inset, 75px 100px 127px 0 rgba(255, 255, 255, 0.23) inset;
}
.metal .lighting:after {
    box-shadow: 0 0 42px 20px rgba(159, 216, 249, 0.747), 0 0 7px 6px rgba(255, 255, 255, 0.9);
  }
.metal .reflection:before {
    opacity: 0.0;
}

/*-----------------------------------------------------------------------------------*/

/* Classe para animação de vibração */
.vibrate {
  animation-name: vibrate;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-iteration-count: infinite;
}

/* Classe para movimento suave */
.smooth-move {
  transition: transform 0.5s ease-in-out;
}

/*---------------------------------------------------------------------------------------*/
.custom-rubber-band {
  animation-name: customRubberBand;
  animation-duration: 1.4s; 
}

@keyframes customRubberBand {
  0% {
    transform: scale(1) translateY(0);
  }
  30% {
    transform: scale(1.25, 0.75)translateY(108);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85) translateY(-350px);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  87% {
    transform: scale(0.8, 1.1) translateY(38);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}


@keyframes zigzag {
  0%, 100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  12% {
    top: 90%;
    left: 20%;
    transform: translate(-20%, -20%);
  }
  25% {
    top: 5%;
    left: 5%;
    transform: translate(-20%, -20%);
  }
  35% {
    top: 90%;
    left: 12%;
    transform: translate(-20%, -20%);
  }
  50% {
    top: 95%;
    left: 95%;
    transform: translate(-80%, -80%);
  }
  75% {
    top: 50%;
    left: 5%;
    transform: translate(-50%, -50%);
  }
  85% {
    top: 10%;
    left: 80%;
    transform: translate(-50%, -50%);
  }
  95% {
    top: 19%;
    left: 45%;
    transform: translate(-50%, -50%);
  }
  95% {
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}