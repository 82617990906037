.postContainerWrapper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postContainerArticles {
    height: 100vw;
    width: 80%;
    margin: 20px;
    padding: 20px;
    background-color: rgba(17, 17, 14, 0.715);
    color: white;
    overflow-x: scroll;
    bottom: 0;
}

.searchBar{
    height: 40px;
    width: 83%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(248, 242, 183, 0.23);
    border-radius: 3px;
    color: rgb(154, 114, 6);
    font-weight: 100;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
    background: -webkit-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
    background: linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
}
.searchBar input {
    width: 38%;
    height: 35px;
    margin-right: 8px;
    margin-left: 8px;
}
@media screen and (max-width: 576px) {
    .searchBar input {
        width: 47%;
        height: 32px;
        margin-right: 6px;
        margin-left: 6px;
    }
    .searchBar{
        height: 36px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(248, 242, 183, 0.23);
        border-radius: 3px;
        color: rgb(154, 114, 6);
        font-weight: 100;
        font-size: 12px;
        background: rgb(0,0,0);
        background: -moz-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
        background: -webkit-linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
        background: linear-gradient(91deg, rgba(0,0,0,1) 0%, rgba(20,19,1,1) 100%);
    }
    .searchBar .yellowbutton{
       width: 50px;
       margin-right: 3px;
       padding: 4px;
    }
  }



.postArticles{
 height: 55px;
}
@media screen and (max-width: 576px) {
  .postArticles{
    height: auto;
  }
}

.postContainerArticles::-webkit-scrollbar {
    background-color: transparent; 
    width: 0;
  }
  
  .postContainerArticles::-webkit-scrollbar-thumb {
    background-color: transparent; 
  }
  
  .postContainerArticles::-webkit-scrollbar-track {
    background-color: transparent; 
  }

/* Estilo para a seção de pesquisa */
.postContainerArticles > div:first-child {
    font-weight: bold;
    margin-bottom: 10px;
}

/* Estilo para a lista de artigos */
.postsFromArticles {
    display: flex;
    flex-wrap: wrap;
}


/* Estilo quando o mouse passa por cima do artigo */
.postArticles:hover {
    transform: translateY(-5px);
}

/* Estilo para o título do artigo */
.postTitleFromArticles {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.postTitleFromArticles h2, h1 {
    font-size: medium;
    letter-spacing: 1.4px;
    font-weight: 600;
}


.postDate{
    color: yellow;
    margin-left: 38px;
    margin-right: 30px;
    background-color: rgb(38, 38, 40);
    font-size: 13px;
}
@media screen and (max-width: 576px) {
    .postDate{
        margin-left: 12px;
        margin-right: 3px;
        font-size: 11px;
    
    }
    .postTitleFromArticles h2, h1 {
        font-size: small;
    }
  }

.postDate h4 {
    font-size: 10px;
}
