/* ARTICLE EXPANDED*/
.ExpandedArticleContainer{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: transparent;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .ExpandedArticle {
    width: 100%;
    margin: 0 auto;
    background-color: #0b0b0be5;
    color: rgb(235, 235, 235);
    display: flex;
    flex-direction: column; 
  }

  .contentExp {
    line-height: 1.6;
    max-width: 55%;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden; 
    overflow-wrap: break-word; 
}

.titleExp {
    line-height: 1.8;
    max-width: 60%;
    margin: 0 auto;
    text-align: center;
}

/* Media query para tablets */
@media (max-width: 1024px) {
    .contentExp {
        max-width: 70%;
    }

    .titleExp {
        max-width: 70%;
    }
}

/* Media query para celulares */
@media (max-width: 600px) {
    .contentExp {
        max-width: 80%;
    }

    .titleExp {
        max-width: 80%;
    }
}
