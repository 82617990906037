/*--------------------------- IN CONSTRUCTION -------------------*/

.inConstruction {
    height: 145vh;
    width: 100vw;
    display: flex;
    justify-content: center; 
    align-items: center;
    overflow: hidden;
  }
  @media screen and (max-width: 1200px) {
    .inConstPic {
      width: 98%;
    }
  }