/* HOME PAGE ----------------------------------------------------------------------------------------------------------------- */
.musicLoader {
  position: absolute;
  height: 60px;
  width: 180px;
  background-color: transparent;
  margin-top: 14%;
  margin-left: 25px;
}

.musicLoader video {
  height: 60px;
  width: 180px;
  z-index: 1;
}

.homePage {
    width: 100%;
    padding-top: 20px;
    color: rgb(220, 240, 71);
    display: flex;
    flex-direction: column;
    width: 100vw;
    overflow-x: hidden;
  }
  @font-face {
    font-family: 'Raleway';
    src: local('Raleway-Regular'), url('../../public/homefont/Raleway-VariableFont_wght.ttf') format('truetype');
    font-weight: normal; 
    font-style: normal; 
  }
  .homePage h1, h2 {
    font-family: 'Raleway', sans-serif;
    margin-bottom: -10px; 
    font-size: 65px;
    letter-spacing: -2px;
  
  }
  
  
  .mainHome {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 20px;
    background-color: black;
    margin: 0;
  }
  .mainHome .hometext {
    margin-top: 29%;
    height: 400px;
    margin-right: 80px;
    width: 65%;
  }
  
  .homeLogo {
    width: 235px;
    height: 220px;
    margin-right: 18px;
    clip-path: circle(40% at 20% 20%);
    filter: blur(10px);
    transition: all 2s ease;
    z-index: 1;
    position: relative;
  }
  .homeLogo:hover {
    clip-path: circle(40%);
    filter: blur(0px);
    cursor: crosshair;
  }
  
  .hometext {
    width: 55%;
    height: 200px;
    margin-left: 25px;
  }
  
  .homeSliceOne{
    height: 100vh;
    width: 100vw;
    background-color: rgb(220, 240, 71);
    margin: 0;
    color:black;
  }
  .homeSliceOne .hometext {
    margin-top: 29%;
    height: 400px;
    width: 58%;
  }
  
  .homeSliceTwo{
    height: 100vh;
    width: 100vw;
    background-color: rgb(2, 2, 82);
    margin: 0;
    color: rgb(46, 232, 46);
  }
  .homeSliceTwo .hometext {
    margin-top: 15%;
    margin-left: 20%;
    height: 400px;
  }
  
  .homeSliceThree{
    height: 100vh;
    width: 100vw;
    background-color: rgb(209, 76, 107);
    margin: 0;
    color: rgb(2, 2, 82);
  }
  .homeSliceThree .hometext {
    margin-top: 13%;
  }
  
  .homeSliceFour{
    height: 100vh;
    width: 100vw;
    background-color: rgb(46, 232, 46);
    color: black;
    margin: 0;
  }
  .homeSliceFour .hometext {
    margin-top: 3%;
    margin-left: 40%;
    height: 400px;
  }
  
  .ContactArticles{
    height: 114vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
  }
  .ContactArticles .hometext{
    width: 50%;
  }
  
  .buttonHomeContainer {
    width: 50%;
    width: 100vw;
  }
  .postContainerAndTitle{
    width: 50%;
    width: 100vw;
  }
  
  
  .hometext .yellowbutton {
    margin-left: 16px;
    justify-content: center;
  }
  
  .postsHome {
    margin-top: 35px;
    display: flex;
    width: 50%;
    min-height: 600px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none; 
  }
  .postContainerAndTitle h1, h2 {
    font-size: 30px;
  }
  
  .postsHome::-webkit-scrollbar {
    width: 0.3rem; 
  }
  
  .postsHome::-webkit-scrollbar-thumb {
    background-color: yellow; 
    border-radius: 4px; 
  }
  
  .postsHome::-webkit-scrollbar-track {
    background-color: transparent; 
  }
  
  .post {
    min-width: 600px;
    height: auto;
    min-height: 400px;
    height: 600px;
    background-color: rgba(11, 11, 11, 0.749);
    margin: 1px;
    padding: 20px;
    transition: background-color 0.6s;
  }
  
  .postHeader .title {
    flex: 50%;
  }
  .postHeader .deletePost {
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .deletePost button {
    border: none;
  
    background: none;
    font-size: 30px;
    cursor: pointer;
  }
  
  .post .postTextContainer {
    word-wrap: break-word;
    height: auto;
    max-height: 400px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  /* Estilos para Extra Pequeno (XS) --------------------------------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 576px) {
 
      .mainHome{
        height: auto;
      }
       .mainHome .hometext{
         width: 100%;
         margin-top: 452px;
         margin-left: -45%;
       }

        .homeSliceOne{
        height: 95vh;
        width: 100vw;
        background-color: rgb(220, 240, 71);
        margin: 0;
        color:black;
      }

      .homeSliceOne .hometext {
        margin-top: 29%;
        height: 400px;
        width: 55%;
      }
      
      .homeSliceTwo{
        height: 95vh;
        width: 100vw;
        background-color: rgb(2, 2, 82);
        margin: 0;
        color: rgb(46, 232, 46);
      }
      .homeSliceTwo .hometext {
        margin-top: 18%;
        margin-left: 20%;
        height: 400px;
      }
      
      .homeSliceThree{
        height: 75vh;
        width: 100vw;
        background-color: rgb(209, 76, 107);
        margin: 0;
        color: rgb(2, 2, 82);
      }
      .homeSliceThree .hometext {
        margin-top: 16%;
      }
      
      .homeSliceFour{
        height: 70vh;
        width: 100vw;
        margin-right: 8%;
        margin-top: 15%;
        background-color: rgb(46, 232, 46);
        color: black;
        margin: 0;
      }
      .homeSliceFour .hometext {
        margin-top: 10%;
        margin-left: 35%;
        height: 400px;
      }


    .homePage h1, h2 {
        font-family: 'Raleway', sans-serif;
        margin-bottom: -2px; 
        font-size: 25px;
        letter-spacing: -2px;
      
      }   

    .post .postTextContainer {
        word-wrap: break-word;
        height: auto;
        max-height: 400px;
        max-width: 180px;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
      }

    .startPlay {
        margin-left:-7% ;
        height: 50px;
    }
    .homeLogo{
        margin-left: -40px;
    }

    .ContactArticles .hometext{
        width: 45%;
      }

    .buttonHomeContainer{
        width: 100px;
        margin-right: 70px;
       
    }
    .ContactArticles{
        height: 90vh;
    }
  }
  
  /* Estilos para Pequeno (SM) */
  @media screen and (min-width: 577px) and (max-width: 768px) {
    
    .homeSliceOne{
        height: 116vh;
        width: 100vw;
        background-color: rgb(220, 240, 71);
        margin: 0;
        color:black;
      }
      .homeSliceOne .hometext {
        margin-top: 29%;
        height: 400px;
        width: 55%;
      }
      
      .homeSliceTwo{
        height: 112vh;
        width: 100vw;
        background-color: rgb(2, 2, 82);
        margin: 0;
        color: rgb(46, 232, 46);
      }
      .homeSliceTwo .hometext {
        margin-top: 18%;
        margin-left: 20%;
        height: 400px;
      }
      
      .homeSliceThree{
        height: 110vh;
        width: 100vw;
        background-color: rgb(209, 76, 107);
        margin: 0;
        color: rgb(2, 2, 82);
      }
      .homeSliceThree .hometext {
        margin-top: 16%;
      }
      
      .homeSliceFour{
        height: 105vh;
        width: 100vw;
        margin-right: 8%;
        margin-top: 15%;
        background-color: rgb(46, 232, 46);
        color: black;
        margin: 0;
      }
      .homeSliceFour .hometext {
        margin-top: 10%;
        margin-left: 35%;
        height: 400px;
      }


    .homePage h1, h2 {
        font-family: 'Raleway', sans-serif;
        margin-bottom: -2px; 
        font-size: 35px;
        letter-spacing: -2px;
      
      }   

    .post .postTextContainer {
        word-wrap: break-word;
        height: auto;
        max-height: 400px;
        max-width: 180px;
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
      }

      .mainHome .hometext{
       margin-top: 35%;
    }
    .startPlay {
        margin-left:-7% ;
    }
    .homeLogo{
        margin-left: -40px;
    }
  }
  
  /* Estilos para Médio (MD) */
  @media screen and (min-width: 769px) and (max-width: 992px) {
    .homePage h1, h2 {
        font-family: 'Raleway', sans-serif;
        margin-bottom: -5px; 
        font-size: 52px;
        letter-spacing: -2px;
      }

    .startPlay {
        margin-left:-52px ;
    }
    .homeLogo{
        margin-left: -20px;
    }

  }
  
  /* Estilos para Extra Grande (XL) */
  @media screen and (min-width: 1201px) {
    .homePage h1 {
        font-family: 'Raleway', sans-serif;
        margin-bottom: -15px; 
        font-size: 62px;
        letter-spacing: -3px;
      
      }
  }
  