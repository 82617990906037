.shareButton {
    height: 49px;
    background-color: transparent;
    color: white;
    margin: 2px;
    border-radius: 8px;
}

@media screen and (max-width: 576px) {
    .shareButton{
      height: 44px;
      width: 85px;
      margin-top: 5px;
      margin-right:8px;
      
    }
  }