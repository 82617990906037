.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw;
  z-index: 1;
}


.contact-wrapper{
    height: 100vh;
    width: 100vw;
    background-color: rgba(33, 1, 40, 0.089);
    overflow-x: hidden;
    display: flex;
    color:white;
    padding: 15px;
}

.text-zone{
    width: 50vw;
    height: 50px;
    font-size: 16px;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 4px;
}


.contact-form {
    width: 100%;
    padding: 10px;
}

ul {
    padding: 0;
    margin-top: 20px;
}
li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 0.6s 0.6s;
      animation-fill-mode: forwards;
    }

li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
textarea{
    width: 100%;
    border: 1px solid white;
    background: rgba(84, 84, 2, 0.195);
    height: 50px;
    font-size: 16px;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
    border-radius: 4px;
}
  

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 1px solid white;
    background: rgba(84, 84, 2, 0.195);
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }

.flat-button {
    color: #000000;
    font-size: 15px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffffff;
    background: yellow;
    text-transform: uppercase;
    text-align: center;
    margin-right: 10px;
    border-radius: 4px;
    z-index: 1;
    &:hover {
      background-color:transparent;
      color: yellow;
   }
}

.info-map {

    position: absolute;
    background-color: #141409;
    top: 10%;
    right: 5%;
    z-index: 5;
    width: 267px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.2s;
    animation-fill-mode: forwards;
    border-radius: 4px;
}

.text-map{
    height: inherit 150px;
    width: 300px;
}

.map-wrap {
    background: rgb(25, 24, 32, 0.1);
    float: right;
    margin: 0;
    margin-left: 15px;
    width: 45%;
    height: 100%;
    border-radius: 4px;
  }

.text-map{
    margin-left: 15px;
    font-size: 15px;
    display: block;
    padding-top: 15px;
    color: #f5f1ef;

}



.leaflet-container {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    border-radius: 4px;
    animation: backInRight 0.8s 1s;
    animation-fill-mode: forwards;
  }


  @media screen and (max-width: 578px) {
    body{
      overflow-x: hidden;
    }

    .contact-wrapper{
       flex-direction: column;
       overflow-x: hidden;
       height: 128vh;
       padding: 5px;
    }

    .text-zone{
      width: 95%;
      z-index: 1;
    }

    .leaflet-container {
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 4px;
      margin-top: 190px;
      animation: backInRight 0.8s 1s;
      animation-fill-mode: forwards;
    }

    .map-wrap {
      float: none;
      width: 92%;
      height: 250px;
      margin-left: 3%;
      position: absolute;
      bottom: -20px;
    }
    
    
    .info-map {
      display: none;
    }
  
    
  .contact-form {
    width: 100%;
  }

  ul {
    padding: 0;
    margin-top: 10px;
  }

  li {
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      height: 25px;
      font-size: 14px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 376px){
    .map-wrap {
      float: none;
      width: 92%;
      height: 170px;
      margin-left: 3%;
      position: absolute;
      bottom: 0;
    }

  }