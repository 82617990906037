/* CARDS ---------------------------------------------------------------------------------------------------------------*/
.preFooter {
    height: 27rem;
    width: auto;
    overflow-x: auto;
    background: white;
    background-image: url('../../public/juno.svg');
    background-size: cover;
    background-position: center;
    white-space: nowrap;
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  
  /* Personalização da barra de rolagem */
  .preFooter::-webkit-scrollbar {
    width: 1px; /* Largura da barra de rolagem */
  }
  
  .preFooter::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .preFooter::-webkit-scrollbar-track {
    background-color: transparent; /* Cor de fundo do track */
  }
  
  .tip {
    margin-top: 25px;
    display: inline-block;
    margin-right: 25px;
    vertical-align: top;
    height: 75%;
    width: 250px;
    border: 2px solid rgba(51, 26, 37, 0.559);
    padding: 10px;
    max-width: 200px;
    white-space: normal;
    position: relative; 
    border-radius: 2px;
  }
  
  .tip::before {
    content: ""; 
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../public/tipcard.jpeg');
    background-size: cover;
    background-position: center;
    opacity: 0.32; 
  
  }
  
  .tip h4 {
    margin-top: 20px;
    color: rgb(252, 242, 219);
    font-weight:770;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    z-index: 1;
  }
  .tip p {
    color: rgb(252, 242, 219);
    font-weight:600;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  
  :root {
    --screen-width: 100vw; 
  }
  
  
  @keyframes parallax {
    0% {
      transform: translateX(calc(100%)); 
    }
    100% {
      transform: translateX(calc(-240%)); 
    }
  }
  
  .parallax-container {
    animation: parallax 30s linear infinite;
    background-size: cover;
    background-position: center;
    white-space: nowrap;
    height: 22rem;
    padding: 10px;
    margin-right: 30px;
  }
  @media screen and (max-width: 398px){
  .parallax-container {
    animation: parallax 25s linear infinite;
  }
  @keyframes parallax {
    0% {
      transform: translateX(calc(100%)); 
    }
    100% {
      transform: translateX(calc(-1250%)); 
    }
  }
  }
  
  @media screen and (min-width: 399px) and (max-width: 578px){
    .parallax-container {
      animation: parallax 29s linear infinite;
    }
    @keyframes parallax {
      0% {
        transform: translateX(calc(100%)); 
      }
      100% {
        transform: translateX(calc(-800%)); 
      }
    }
    }
  
    @media screen and (min-width: 579px) and (max-width: 769px){
      .parallax-container {
        animation: parallax 25s linear infinite;
      }
      @keyframes parallax {
        0% {
          transform: translateX(calc(100%)); 
        }
        100% {
          transform: translateX(calc(-300%)); 
        }
      }
      }
  
  .parallax-container.pause-animation {
    animation-play-state: paused;
  }