.projects {
    height: 100vh;
    width: 100vw;
    background: rgb(226,255,245);
    background: -moz-linear-gradient(91deg, rgba(226,255,245,1) 0%, rgba(179,255,249,1) 100%);
    background: -webkit-linear-gradient(91deg, rgba(226,255,245,1) 0%, rgba(179,255,249,1) 100%);
    background: linear-gradient(91deg, rgba(226,255,245,1) 0%, rgba(179,255,249,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2fff5",endColorstr="#b3fff9",GradientType=1);
}

.active-div {
    background-color: rgba(229, 250, 250, 0.9);
    height: 558px;
    width: 60%;
    z-index: 5;
    border: solid 1px rgb(3, 7, 230);
    padding: 15px;
    color: rgb(3, 7, 230);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
}

.active-div a {
    color: rgb(123, 123, 2);
}

@media screen and (min-width: 769px) and (max-width: 578px){
    .active-div {
        height: 398px;
        width: 65%;
    }
}
@media screen and (max-width: 768px){
    .active-div {
        height: 400px;
        width: 74%;
    }

}

.animate__fadeIn {
    animation-duration: 3s; 
  }
